.navbar-toggler {
    font-size: 20px;
    font-weight: var(--font-weight-light);
}

a.nav-link {
    color: var(--color-light);
}

a.nav-link:hover {
    color: var(--color-light);
    text-decoration: underline;
}