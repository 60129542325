/*
  Customize Bootstrap 5.x
*/

$body-color: white;
$border-radius: 20px;
$border-radius-sm: 10px;
$border-radius-lg: 30px;

$light: white;
$dark: #364958;



@import '~bootstrap/scss/bootstrap.scss';

/*
  Experimental translucent background that changes opacity based on Bootstrap breakpoint
*/

$translucent-color: white;

.bg-screen { background-color: rgba($translucent-color, 0.7); }
@include media-breakpoint-up(sm) { .bg-screen { background-color: rgba($translucent-color, 0.7); } } 
@include media-breakpoint-up(md) { .bg-screen { background-color: rgba($translucent-color, 0.5); } } 
@include media-breakpoint-up(lg) { .bg-screen { background-color: rgba($translucent-color, 0.0); } } 
@include media-breakpoint-up(xl) { .bg-screen { background-color: rgba($translucent-color, 0.0); } } 
@include media-breakpoint-up(xxl) { .bg-screen { background-color: rgba($translucent-color, 0.0); } } 

.divider { height: 5ex; }
@include media-breakpoint-up(sm) { .divider { height: 5ex; } } 
@include media-breakpoint-up(md) { .divider { height: 10ex; } } 
@include media-breakpoint-up(lg) { .divider { height: 20ex; } } 
@include media-breakpoint-up(xl) { .divider { height: 20ex; } } 
@include media-breakpoint-up(xxl) { .divider { height: 20ex; } } 

/*
.biography { max-width: 100%; }
@include media-breakpoint-up(sm) { .biography { max-width: 100%; } } 
@include media-breakpoint-up(md) { .biography { max-width: 100%; } } 
@include media-breakpoint-up(lg) { .biography { max-width: 100%; } } 
@include media-breakpoint-up(xl) { .biography { max-width: 286px; } } 
@include media-breakpoint-up(xxl) { .biography { max-width: 286px; } } */