label {
    font-size: 20px;
    font-weight: var(--font-weight-semibold);
    padding-left: 5px;
    text-transform: uppercase;
}

label .required {
    color: #f4a259;
}

.form-check-inline label {
    font-weight: var(--font-weight-light);
}

input.form-control {
    background-color: inherit;
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-bottom: solid 1px white;
    border-left: none;
    color: white;
    font-size: 20px;
}

input.form-control:focus {
    background-color: inherit;
    border-top: none;
    border-right: none;
    border-bottom: solid 3px white;
    border-left: none;
    color: white;
}

input.form-control::placeholder {
    color: rgb(200,200,200);
}
