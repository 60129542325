:root {
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-semibold: 600;

    --color-1: #2f3d41;
    --color-2: #2F6067;
    --color-3: #00818D;
    --color-4: #ffffff00;

    --color-dark: #364958;
}

html {
    zoom: 1;
}

body {
    background-color: var(--color-light);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: auto;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: white;
    text-decoration: underline;
}

h1.impact-headline {
    color: var(--color-light);
    font-size: 50px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
}

h2.sub-impact-headline {
    color: var(--color-dark);
    font-size: 30px;
    font-weight: var(--font-weight-light);
}

h2.headline {
    font-size: 40px;
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
}

.body-copy {
    font-size: 20px;
    font-weight: var(--font-weight-regular);
    line-height: 30px;
}

.background-1 { background-color: var(--color-1); }
.background-2 { background-color: var(--color-2); }
.background-3 { background-color: var(--color-3); }
.background-4 { background-color: var(--color-4); }