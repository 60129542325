.address {
    font-size: 20px;
    font-weight: var(--font-weight-light);
}

#footer .nav-link {
    display: inline;
    font-size: 16px;
    font-weight: var(--font-weight-regular);
    margin-top: auto;
    text-decoration: underline;
    text-transform: uppercase;
}